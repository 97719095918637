import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import TopNavigationBar from "../../components/TopNavigationBar";
import Seo from "../../components/Seo";
import Button from "../../components/Button";
import Footer from "../../components/Footer";
import Loader from "../../components/Loader";
import ImageLoader from '../../components/ImageLoader';

import "./index.css";

import { error } from "../../utils/toast";
import { get } from "../../utils/http";
import { formatWalletAddress } from "../../utils/tools";
import { useGlobalState } from "../../store/index";

const PayResult = () => {
  const navigate = useNavigate();
  const [globalIsLogin] = useGlobalState(
    "globalIsLogin"
  );
  const [nft, setNft] = useState({});
  const [user, setUser] = useState({});
  const [loaderState, setLoaderState] = useState(true);
  const [payState, setPayState] = useState(false);
  const [toUserId, setToUserId] = useState(0);
  const searchParams = new URLSearchParams(window.location.search);
  const payResult = searchParams.get("payState");
  const payOrderID = searchParams.get("orderId");
  const elementType = {
    1: "Cơ bản",
    2: "Lửa"
  };

  useEffect(() => {
    async function fetchData() {
      await loadLocalToken();
    }
    if (globalIsLogin === true) {
      fetchData();
    }
  }, [globalIsLogin]);

  const loadLocalToken = async () => {
    if (payResult && payOrderID) {
      if (payResult === "success") {
        await fetchOrder();
        setPayState(true);
      } else if (payResult === "partialsuccess") {
        setTimeout(() => {
          fetchOrder();
          setPayState(true);
        }, 30000);
      } else if (payResult === "failed") {
        await fetchOrder();
        setPayState(false);
      } else {
        error(`Parameter error.`);
      }
    } else {
      error(`Parameter error.`);
    }
  };

  const fetchOrder = async () => {
    const rep = await get("yeah1Payment/getOrderByTableID", {
      orderTableID: payOrderID,
    });
    if (rep.code === 0) {
      if (rep.data.orderInfo.order_type === 1) {
        setNft(rep.data.nftList[0]);
        const to_user_id = rep.data.orderInfo.to_user_id;
        setToUserId(to_user_id);
        await fetchUserNickName(to_user_id);
        setLoaderState(false);
      } else {
        error(`Parameter error.`);
      }
    } else {
      error(rep.msg);
    }
  };

  const fetchUserNickName = async (_to_user_id) => {
    const rep = await get("yeah1Apps/getUserNickName", {
      id: _to_user_id,
    });
    if (rep.code === 0) {
      setUser(rep.data);
    } else {
      error(rep.msg);
    }
  };

  const handleMyNfts = () => {
    navigate("/myNftList");
  };

  return (
    <div className="app">
      <Seo
        title={`Yvote - Gửi`}
        description={`Yvote - Funfusion Gửi`}
        keywords={`Yvote,Funfusion,NFT,Web3,Gửi`}
        image={`${window.location.host}/${process.env.PUBLIC_URL}/images/funfusion.png`}
        url={window.location.href}
      />
      <TopNavigationBar check={true} />
      <div className="pay-result">
        <div
          className={payState === true ? "paystate success" : "paystate failed"}
        >
          <div className="icon"></div>
          <div className="msg">
            {payState === true ? "Gửi thành công!" : "Thanh toán thất bại"}
          </div>
        </div>
        <div className="pay-nft-box">
          <div className="pay-nft">
            <div className="nft-poster">
              <ImageLoader
                src={nft.show_image}
                alt={nft.show_name}
              />
            </div>
          </div>
          <div className="nft-info">
            <div className="val">
              {nft.show_name}
            </div>
            <div className="val">Cấp độ {nft.nft_lv || 1}</div>
            <div className="val">{elementType[nft.nft_type || 1]}</div>
            {nft.nft_token_id && (
              <div className="val">
                TokenID:<span> {formatWalletAddress(nft.nft_token_id)}</span>
              </div>
            )}
          </div>
          <div className="line"></div>
          <div className="sent-to">Quà tặng số này sẽ được gửi tới:</div>
          {user && (
            <div className="sent-user">
              <ImageLoader css="sent-face" src={user.head_image} />
              <div className="sent-info">
                <div className="num">{toUserId}</div>
                <div className="nickname">
                  {user.nickName && user.nickName}
                </div>
              </div>
            </div>
          )}
        </div>
        <Button size={"small-default"} onClick={handleMyNfts}>
          Quay lại quà tặng số của tôi
        </Button>
      </div>
      <Footer />
      <Loader isOpen={loaderState} />
    </div>
  );
};

export default PayResult;
