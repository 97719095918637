import React, { useState, useEffect } from 'react';
const defaultSrc = process.env.PUBLIC_URL + '/images/funfusion.png';

const ImageLoader = ({ css, src, alt, onClick }) => {
  const [srcToLoad, setSrcToLoad] = useState(defaultSrc);

  useEffect(() => {
    setSrcToLoad(src);
  }, [src]);

  const handleError = () => {
    setSrcToLoad(defaultSrc);
  };

  const handleClick = () => {
    onClick && onClick();
  };

  return (
    <img
      className={css}
      src={srcToLoad}
      alt={alt}
      onError={handleError}
      onClick={handleClick}
    />
  );
};

export default ImageLoader;