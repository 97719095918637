import React, { useState, useEffect } from "react";
import { Bnb } from 'react-web3-icons';

import TopNavigationBar from "../../components/TopNavigationBar";
import Seo from "../../components/Seo";
import Button from "../../components/Button";
import Loader from "../../components/Loader";
import Modal from "../../components/Modal";
import Footer from "../../components/Footer";

import "./index.css";

import config from "../../utils/global";
import { error } from "../../utils/toast";
import { get } from "../../utils/http";
import { isWalletAddress, replaceWalletAddress, formatWalletAddress, formatNumberWithCommas, isMobile } from "../../utils/tools";
import { useGlobalState } from "../../store/index";

const NftWithdraw = () => {
  const [globalIsLogin] = useGlobalState(
    "globalIsLogin"
  );
  const [modalOpen, setModalOpen] = useState(false);
  const [loaderState, setLoaderState] = useState(true);
  const [walletAddr, setWalletAddr] = useState("");
  const [errorMsg, setErrorMsg] = useState(false);
  const [nextState, setNextState] = useState(false);
  const [nft, setNft] = useState({});
  const [nftsTagList, setNftsTagList] = useState([]);
  const [isAgree, setIsAgree] = useState(false);
  const searchParams = new URLSearchParams(window.location.search);
  const nftId = searchParams.get("nftId");

  useEffect(() => {
    async function fetchData() {
      if (nftId) {
        await fetchNftsTags();
      } else {
        error(`Parameter error.`);
      }
    }
    if (globalIsLogin === true) {
      fetchData();
    }
  }, [globalIsLogin]);

  const fetchMyNFTDetail = async () => {
    const rep = await get("yeah1Apps/getMyNFTById", {
      nftId: nftId
    });
    if (rep.code === 0) {
      setNft(rep.data.userNFTInfo);
      await fetchMyWallet();
      setLoaderState(false);
    } else {
      error(rep.msg);
    }
  };

  const fetchMyWallet = async () => {
    const rep = await get("yeah1Apps/getUserVoteInfo", {});
    if (rep.code === 0) {
      setWalletAddr(replaceWalletAddress(rep.data.userInfo.wallet_address));
      setNextState(true);
    } else {
      error(rep.msg);
    }
  };

  const fetchNftsTags = async () => {
    const rep = await get("yeah1Apps/getNFTConfigBySeason", {
      seasonID: 1,
    });
    if (rep.code === 0) {
      setNftsTagList(rep.data.seasonNFTCfgList);
      await fetchMyNFTDetail();
    } else {
      error(rep.msg);
    }
  };

  const filterWithdrawTax = (_nft_lv) => {
    let filterWithdrawTax = nftsTagList.filter(
      (word) => word.nft_lv === _nft_lv
    );
    if (filterWithdrawTax.length >= 1) {
      return filterWithdrawTax[0].withdraw_tax;
    } else {
      return "";
    }
  };

  const handleNext = () => {
    if (isWalletAddress(walletAddr) === true) {
      openModal();
    } else {
      setErrorMsg(true);
    }
  };

  const handleInputChange = (event) => {
    setWalletAddr(event.target.value);
    if (event.target.value.length >= 1) {
      setNextState(true);
    } else {
      setErrorMsg(false);
      setNextState(false);
    }
  };

  const handleNftWithdraw = () => {
    closeModal();
    setLoaderState(true);
    fetchWithdrawNFT();
  };

  const fetchWithdrawNFT = async () => {
    const rep = await get("yeah1Apps/withDrawNft", {
      nftID: nftId,
      toWalletAddr: walletAddr,
    });
    if (rep.code === 0) {
      window.location.href = `${rep.data}`;
    } else {
      error(rep.msg);
    }
  };

  const handleCancel = () => {
    closeModal();
  };

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleOpen = () => {
    if (isMobile()) {
      window.location.href = `${config.api.agreeURL}`;
    } else {
      window.open(config.api.agreeURL);
    }
  };

  const handleShare = () => {
    if (isMobile()) {
      window.location.href = "https://zalo.me/g/jjmtrd741";
    } else {
      window.open("https://zalo.me/g/jjmtrd741");
    }
  };

  const handleCheckboxChange = (e) => {
    setIsAgree(e.target.checked);
  };

  return (
    <div className="app">
      <Seo
        title={`Yvote - Rút`}
        description={`Yvote - Funfusion Rút`}
        keywords={`Yvote,Funfusion,NFT,Web3,Rút`}
        image={`${window.location.host}/${process.env.PUBLIC_URL}/images/funfusion.png`}
        url={window.location.href}
      />
      <TopNavigationBar back={true} />
      <div className="nft-withdraw">
        <div className="prompt">
          Nhập địa chỉ ví để rút về:
        </div>
        <div className="value">
          <input
            className="input"
            placeholder="Vui lòng nhập"
            value={walletAddr}
            onChange={handleInputChange}
          />
        </div>
        {errorMsg === true ? (
          <div className="error">Invalid Web3.0 Wallet!</div>
        ) : (
          <div className="error"></div>
        )}
        <Button
          size={nextState === false ? "lock" : "default"}
          onClick={handleNext}
        >
          Rút
        </Button>
        <div className="tips">
          <p>
            Sau khi rút, quà tặng số sẽ được chuyển đến ví <Bnb size={20} /> BNB CHAIN của bạn, quà tặng số lúc này có thể giao dịch trên thị trường.
          </p>
          <p>
            Bạn có thể xem trạng thái của quà tặng số trên <Bnb size={20} /> BNB CHAIN trong <a href="/history">Lịch sử giao dịch</a>.
          </p>
          <div className="zalo" onClick={handleShare}>
            <div className="icon-zalo"></div>
            <div className="menu-text">
              Hỏi <span className="w">FunFusion</span> về các vấn đề liên quan đến quà tặng số.
              <p className="sm">(Đây là cộng đồng mở về NFT không được quản lý trực tiếp bởi đơn vị điều hành yvote)</p>
            </div>
            <div className="icon-arrow-right"></div>
          </div>
        </div>
      </div>
      <Footer />
      <Loader isOpen={loaderState} />
      <Modal styleCss={"notification"} isOpen={modalOpen} onClose={closeModal}>
        <div className="withdraw-confirm">
          <div className="confirm-title">
            Bạn đã gửi quà tặng số về ví
          </div>
          <div className="confirm-text">
            Địa chỉ:<span>{formatWalletAddress(walletAddr)}</span>
          </div>
          <div className="confirm-text">
            Phí giao dịch:<span>{formatNumberWithCommas(filterWithdrawTax(nft.nft_lv))}</span>
          </div>
          <div className="confirm-readme">
            <input
              className="checkbox"
              type="checkbox"
              name="agree"
              checked={isAgree}
              onChange={handleCheckboxChange}
            />
            Tôi đã đọc và đồng ý với <span onClick={handleOpen}>Điều khoản và Điều kiện</span>
          </div>
          <div className="confirm-btns">
            <Button size={isAgree === true ? "default" : "lock"} onClick={handleNftWithdraw}>
              Đồng ý
            </Button>
            <div className="change-wallet" onClick={handleCancel}>
              Hủy bỏ
            </div>
          </div>
          <div className="confirm-tips">
            Lưu ý: Vì lý do an ninh, nếu thanh toán bất bại hoặc hủy bỏ, quà tặng số sẽ bị khóa trong vòng 24 giờ và trả lại sau đó.
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default NftWithdraw;
