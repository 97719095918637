// src/gtag.js
export const trackSelectItemEvent = (itemListName, items) => {
    if (typeof window.gtag === 'function') {
        window.gtag('event', 'select_item', {
            item_list_name: itemListName,
            items: items,
        });
    } else {
        console.error('Google Tag is not defined');
    }
};

export const trackPurchaseEvent = (transactionId, value, currency, items, tax = null) => {
    const eventParams = {
        transaction_id: transactionId,
        value: value,
        currency: currency,
        items: items,
    };

    if (tax !== null) {
        eventParams.tax = tax;
    }

    if (typeof window.gtag === 'function') {
        window.gtag('event', 'purchase', eventParams);
    } else {
        console.error('Google Tag is not defined');
    }
};