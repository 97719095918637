import global from "../config/global.json";

console.log(global[process.env.REACT_APP_ENV].baseURL);

const config = {
    network: {
        networkName: global[process.env.REACT_APP_ENV].networkName,
        networkUrl: global[process.env.REACT_APP_ENV].networkUrl,
        chainId: global[process.env.REACT_APP_ENV].chainId,
        currencySymbol: global[process.env.REACT_APP_ENV].currencySymbol,
        currencyDecimals: global[process.env.REACT_APP_ENV].currencyDecimals,
        exploreUrl: global[process.env.REACT_APP_ENV].exploreUrl
    },
    api: {
        baseURL: global[process.env.REACT_APP_ENV].baseURL,
        siteURL: global[process.env.REACT_APP_ENV].siteURL,
        loginURL: global[process.env.REACT_APP_ENV].loginURL,
        voteURL: global[process.env.REACT_APP_ENV].voteURL,
        agreeURL: global[process.env.REACT_APP_ENV].agreeURL,
        xSign: global[process.env.REACT_APP_ENV].xSign
    },
    contract: {
        contractAddress: global[process.env.REACT_APP_ENV].contractAddress
    }
};

export default config;