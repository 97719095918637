import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import TopNavigationBar from "../../components/TopNavigationBar";
import Seo from "../../components/Seo";
import Button from "../../components/Button";
import Loader from "../../components/Loader";
import ImageLoader from '../../components/ImageLoader';
import Footer from "../../components/Footer";

import "./index.css";

import { error } from "../../utils/toast";
import { get } from "../../utils/http";
import { useGlobalState } from "../../store/index";

const NftSend = () => {
  const navigate = useNavigate();
  const [globalIsLogin] = useGlobalState(
    "globalIsLogin"
  );
  const [loaderState, setLoaderState] = useState(true);
  const [yeah1Id, setYeah1Id] = useState("");
  const [errorMsg, setErrorMsg] = useState(false);
  const [nextState, setNextState] = useState(false);
  const [nft, setNft] = useState({});
  const searchParams = new URLSearchParams(window.location.search);
  const nftId = searchParams.get("nftId");

  useEffect(() => {
    async function fetchData() {
      if (nftId) {
        await fetchMyNFTDetail();
      } else {
        error(`Parameter error.`);
      }
    }
    if (globalIsLogin === true) {
      fetchData();
    }
  }, [globalIsLogin]);

  const fetchMyNFTDetail = async () => {
    const rep = await get("yeah1Apps/getMyNFTById", {
      nftId: nftId
    });
    if (rep.code === 0) {
      setNft(rep.data.userNFTInfo);
      setLoaderState(false);
    } else {
      error(rep.msg);
    }
  };

  const handleNext = () => {
    if (yeah1Id.length > 0) {
      fetchUserNickName();
    } else {
      setErrorMsg(true);
    }
  };

  const handleInputChange = (event) => {
    setYeah1Id(event.target.value);
    if (event.target.value.length >= 1) {
      setNextState(true);
    } else {
      setNextState(false);
    }
  };

  const fetchUserNickName = async () => {
    const rep = await get("yeah1Apps/getUserNickName", {
      id: yeah1Id,
    });
    if (rep.code === 0) {
      if (rep.data.nickName) {
        navigate(`/payConfirm?nftId=${nftId}&yeah1Id=${yeah1Id}`);
      } else {
        setErrorMsg(true);
      }
    } else {
      setErrorMsg(true);
      error(rep.msg);
    }
  };

  const handleMyWeb3Info = () => {
    navigate("/myWeb3Info");
  };

  return (
    <div className="app">
      <Seo
        title={`Yvote - Gửi`}
        description={`Yvote - Funfusion Gửi`}
        keywords={`Yvote,Funfusion,NFT,Web3,Gửi`}
        image={`${window.location.host}/${process.env.PUBLIC_URL}/images/funfusion.png`}
        url={window.location.href}
      />
      <TopNavigationBar back={true} check={true} />
      <div className="nft-send">
        {nft && (
          <div className="gift">
            <div className="sm-gift">
              <ImageLoader src={`${process.env.PUBLIC_URL}/images/gift.png`} alt="send" />
            </div>
            <ImageLoader
              src={nft.show_image}
              alt="send"
            />
          </div>
        )}
        <div className="prompt">
          Nhập FunID của người nhận:
          <div className="span">Có thể xem tại "<span onClick={handleMyWeb3Info}>Thông tin web3 của tôi</span>"</div>
        </div>
        <div className="value">
          <input
            className="input"
            placeholder="Vui lòng nhập"
            value={yeah1Id}
            onChange={handleInputChange}
          />
        </div>
        {errorMsg === true ? (
          <div className="error">Không tìm thấy người nhận.</div>
        ) : (
          <div className="error"></div>
        )}
        <Button
          size={nextState === false ? "lock" : "default"}
          onClick={handleNext}
        >
          Gửi
        </Button>
      </div>
      <Footer />
      <Loader isOpen={loaderState} />
    </div>
  );
};

export default NftSend;
