import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import routesConfig from '../config/routes.json';

const generateRoutes = () => {
    return routesConfig.routes.map(route => (
        <Route
            key={route.path}
            path={route.path}
            element={React.createElement(require(`../pages/${route.component}`).default)}
        />
    ));
};

const AppRouter = () => {
    return (
        <Router>
            <Routes>
                {generateRoutes()}
            </Routes>
        </Router>
    );
};

export default AppRouter;
