import React, { useEffect } from "react";

import "./index.css";

const Modal = ({ styleCss, isOpen, onClose, children }) => {
  useEffect(() => {
    const body = document.querySelector('.app');
    if (isOpen === true) {
      body.style.overflow = "hidden";
    } else {
      body.style.overflow = "auto";
    }
    return () => {
      body.style.overflow = "auto";
    };
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  const skinList = {
    default: "default",
    alert: "alert",
    voting: "alert",
    votes: "alert",
    pay: "alert",
    failed: "alert",
    notification: "alert",
  };

  const styleClass = `modal-${skinList[styleCss]}`;
  const handleClose = () => {
    onClose && onClose();
  };

  return (
    <>
      <div className="modal-layer"></div>
      <div className="modal-content">
        <div className={styleClass}>
          <div className="head">
            <div
              className={`icon-${styleCss === "default" ? "close" : styleCss}`}
              onClick={handleClose}
            ></div>
          </div>
          <div className="body">{children}</div>
        </div>
      </div>
    </>
  );
};

export default Modal;
