import React, { useState, useEffect } from "react";

import TopNavigationBar from "../../components/TopNavigationBar"; // 导入顶部导航栏组件
import Seo from "../../components/Seo";
import Loader from "../../components/Loader";
import Footer from "../../components/Footer";

import "./index.css";

import { error } from "../../utils/toast";
import { get } from "../../utils/http";
import { useGlobalState } from "../../store/index";

const MyRights = () => {
  const [globalIsLogin] = useGlobalState(
    "globalIsLogin"
  );
  const [loaderState, setLoaderState] = useState(true);
  const [userInfo, setUserInfo] = useState({});
  const levelsList = [
    {
      "name": 1,
      "description": "level 1(fire) - Create a unique set of handmade commemorative NFTs and signed physical cards for everyone."
    },
    {
      "name": 2,
      "description": "level 2(fire) - Create a unique set of handmade commemorative NFTs and signed physical cards for everyone."
    },
    {
      "name": 3,
      "description": "level 2(fire) - Create a unique set of handmade commemorative NFTs and signed physical cards for everyone."
    },
    {
      "name": 4,
      "description": "level 2(fire) - Create a unique set of handmade commemorative NFTs and signed physical cards for everyone."
    },
    {
      "name": 5,
      "description": "level 5(fire) - Create a unique set of handmade commemorative NFTs and signed physical cards for everyone."
    }
  ];

  useEffect(() => {
    async function fetchData() {
      await fetchMyUser();
    }
    if (globalIsLogin === true) {
      fetchData();
    }
  }, [globalIsLogin]);

  const fetchMyUser = async () => {
    const rep = await get("yeah1Apps/getUserVoteInfo", {});
    if (rep.code === 0) {
      setUserInfo(rep.data.userVoteInfo);
      setLoaderState(false);
    } else {
      error(rep.msg);
    }
  };

  return (
    <div className="app">
      <Seo
        title={`Yvote - Quyền lợi`}
        description={`Yvote - Funfusion Quyền lợi`}
        keywords={`Yvote,Funfusion,NFT,Web3,Quyền lợi`}
        image={`${window.location.host}/${process.env.PUBLIC_URL}/images/funfusion.png`}
        url={window.location.href}
      />
      <TopNavigationBar back={false} check={true} />
      <div className="my-rights">
        <ul className="lev-list">
          {levelsList.map((item, index) => {
            return (
              <li key={`lev_${index}`} className={userInfo.user_last_lv === item.name ? "chked" : ""}>
                {item.description}
                {userInfo.user_last_lv === item.name && (
                  <span>contact customer servie</span>
                )}
              </li>
            )
          })}
        </ul>
      </div >
      <Footer />
      <Loader isOpen={loaderState} />
    </div >
  );
};

export default MyRights;
