import config from "../utils/global";
import CryptoJS from 'crypto-js';

export function formatPhoneNumber(phoneNumber) {
    return phoneNumber.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2');
}

export function formatWalletAddress(walletAddress) {
    let addr = replaceWalletAddress(walletAddress);
    let length = addr.length;

    if (length > 11) {
        const prefix = addr.substring(0, 5);
        const suffix = addr.substring(length - 3);
        const maskedPart = '*'.repeat(Math.min(length - 10, 3));
        return `${prefix}${maskedPart}${suffix}`;
    } else {
        return addr;
    }
}

export function replaceWalletAddress(walletAddress) {
    const regex = /^0x[a-fA-F0-9]{40}$/;
    if (regex.test(walletAddress)) {
        return walletAddress;
    } else {
        return `0x${walletAddress}`;
    }
}

export function formatTimestamp(timestamp) {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
    const formattedTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    return formattedTime;
}

export function formatVietnamTime(timestamp) {
    const date = new Date(timestamp * 1000);
    const options = {
        timeZone: 'Asia/Ho_Chi_Minh',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
    };
    const formatter = new Intl.DateTimeFormat('vi-VN', options);
    return formatter.format(date);
};

export function formatVndDate(dateString) {
    const date = new Date(dateString);
    const timePart = date.toTimeString().split(' ')[0];
    const datePart = date.toISOString().split('T')[0];
    const [year, month, day] = datePart.split('-');
    const formattedDate = `${day}/${month}/${year}`;
    return `${timePart} ${formattedDate}`;
};

export function isWalletAddress(addr) {
    const regex = /^0x[a-fA-F0-9]{40}$/;
    return regex.test(addr);
}

export function getHash(input) {
    const encryptedMessage = CryptoJS.HmacMD5(input, config.api.xSign).toString();
    return encryptedMessage;
}

export function getParamsHash(param) {
    const dic = eval(param || {});
    const res = Object.keys(dic).sort();
    let requestString = '';
    for (const key in res) {
        requestString += `${res[key]}=${window.btoa(unescape(encodeURIComponent(dic[res[key]])))}`;
    }
    const sign = getHash(requestString.toLowerCase());
    return sign;
};

export function getQueryParams(param) {
    const queryString = window.location.search.substring(1);
    const params = queryString.split('&');
    const paramObj = {};
    params.forEach(paramPair => {
        const [key, value] = paramPair.split('=');
        paramObj[decodeURIComponent(key)] = decodeURIComponent(value);
    });
    return paramObj[param];
}

export function queryUrl() {
    const url = window.location.href;
    const startIndex = url.indexOf("callback_url=");
    const substring = startIndex !== -1 ? url.substring(startIndex + "callback_url=".length) : null;
    // console.log('queryUrl=>', substring);
    return substring;
}

export function formatNumberWithCommas(number) {
    const formatter = new Intl.NumberFormat('vi-VN', {
        style: 'currency',
        currency: 'VND',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    });
    const formattedNumber = formatter.format(number);
    return formattedNumber.replace('₫', 'đ');
}

export function isMobile() {
    if (navigator.userAgentData) {
        return navigator.userAgentData.mobile;
    } else {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        return /android|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);
    }
}