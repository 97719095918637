import React, { useEffect } from "react";
import "./index.css";

const Loader = ({ isOpen }) => {
  useEffect(() => {
    const body = document.body;
    if (isOpen === true) {
      body.style.overflow = "hidden";
    } else {
      body.style.overflow = "auto";
    }
    return () => {
      body.style.overflow = "auto";
    };
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  return (
    <div className="loader">
      <div className="loader-overlay" />
      <div className="loader-content">
        <div className="funfusion"></div>
        <div className="loading"></div>
      </div>
    </div>
  );
};

export default Loader;
