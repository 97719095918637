import React, { useEffect } from "react";
import { FaLink } from "react-icons/fa";
import copy from "clipboard-copy";

import TopNavigationBar from "../../components/TopNavigationBar";
import Seo from "../../components/Seo";
import ImageLoader from "../../components/ImageLoader";
import Footer from "../../components/Footer";

import "./index.css";

import config from "../../utils/global";
import { success, error } from "../../utils/toast";

const Faq = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const q = searchParams.get("q");

  useEffect(() => {
    handleScroll(q);
  }, []);

  const handleScroll = (targetId) => {
    const targetElement = document.getElementById(targetId);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleCopy = (keys) => {
    let copyText = `${config.api.siteURL}/faq?q=${keys}`;
    copy(copyText)
      .then(() => {
        success(`Sao chép thành công.`);
      })
      .catch(() => {
        error("Sao chép thất bại.");
      });
  };

  return (
    <div className="app">
      <Seo
        title={`Yvote - Hướng dẫn`}
        description={`Yvote - Funfusion Hướng dẫn`}
        keywords={`Yvote,Funfusion,NFT,Web3,Hướng dẫn,FAQ`}
        image={`${window.location.host}/${process.env.PUBLIC_URL}/images/funfusion.png`}
        url={window.location.href}
      />
      <TopNavigationBar check={false} />
      <div id="faq" className="faq-container">
        <h1>1.Giới thiệu kiến thức cơ bản</h1>
        <div className="faq-section">
          <h2 id="s1">Blockchain là gì?<FaLink title="Sao chép liên kết này" onClick={() => handleCopy('s1')} /></h2>
          <p>Blockchain được biết đến nhiều nhất với vai trò quan trọng trong các hệ thống tiền điện tử để duy trì hồ sơ giao dịch an toàn và phi tập trung. Tuy nhiên, thực tế là công nghệ này không chỉ giới hạn trong lĩnh vực tiền điện tử. Blockchain có thể được sử dụng như “sổ cái” dữ liệu trong bất kỳ ngành nào để ngăn dữ liệu bị thay đổi hoặc gian lận.</p>
          <p>Kể từ khi Bitcoin được giới thiệu vào năm 2009, việc sử dụng Blockchain đã bùng nổ thông qua việc tạo ra nhiều loại tiền điện tử, ứng dụng tài chính phi tập trung (DeFi), mã thông báo không thể thay thế (NFT) và hợp đồng thông minh.</p>
        </div>

        <div className="faq-section">
          <h2 id="s2">Ví blockchain là gì? Sự khác biệt giữa nó và ví điện tử thông thường là gì?<FaLink title="Sao chép liên kết này" onClick={() => handleCopy('s2')} /></h2>
          <p>Ví blockchain, thường được gọi là ví tiền điện tử, là ví kỹ thuật số cho phép người dùng lưu trữ, quản lý và giao dịch các loại tiền điện tử như Bitcoin, Ethereum và các loại khác. Nó sử dụng công nghệ blockchain để lưu trữ an toàn các khóa riêng tư và công khai, cho phép người dùng gửi và nhận tiền kỹ thuật số và theo dõi số dư của họ.</p>
          <p>Ví Blockchain và ví điện tử thông thường có một số điểm khác biệt quan trọng như sau:</p>
          <ul>
            <li>
              <strong>Công nghệ nền tảng:</strong>
              <ul>
                <li>Ví Blockchain: Dựa trên công nghệ blockchain, là một hệ thống phi tập trung và minh bạch. Mọi giao dịch được ghi lại trên sổ cái công khai và không thể thay đổi.</li>
                <li>Ví điện tử thông thường: Thường dựa trên cơ sở dữ liệu tập trung do một tổ chức hoặc công ty quản lý. Ví dụ: Ví Momo, PayPal.</li>
              </ul>
            </li>
            <li>
              <strong>Bảo mật:</strong>
              <ul>
                <li>Ví Blockchain: Mức độ bảo mật cao hơn nhờ vào mã hóa và phân quyền. Người dùng kiểm soát hoàn toàn các khóa cá nhân (private keys) của mình.</li>
                <li>Ví điện tử thông thường: Bảo mật phụ thuộc vào nhà cung cấp dịch vụ. Người dùng không kiểm soát trực tiếp các khóa cá nhân.</li>
              </ul>
            </li>
            <li>
              <strong>Quyền kiểm soát:</strong>
              <ul>
                <li>Ví Blockchain: Người dùng có quyền kiểm soát toàn bộ số tiền và giao dịch của mình, không phụ thuộc vào bên thứ ba.</li>
                <li>Ví điện tử thông thường: Nhà cung cấp dịch vụ có thể có quyền kiểm soát tài khoản và giao dịch của người dùng, và thậm chí có thể đóng băng tài khoản trong một số trường hợp.</li>
              </ul>
            </li>
            <li>
              <strong>Phí giao dịch:</strong>
              <ul>
                <li>Ví Blockchain: Phí giao dịch thường được tính dựa trên mức độ bận rộn của mạng lưới và không phụ thuộc vào số tiền giao dịch.</li>
                <li>Ví điện tử thông thường: Phí giao dịch có thể do nhà cung cấp dịch vụ quyết định và thường được tính dựa trên phần trăm của số tiền giao dịch.</li>
              </ul>
            </li>
            <li>
              <strong>Đa dạng hóa tài sản:</strong>
              <ul>
                <li>Ví Blockchain: Có thể lưu trữ nhiều loại tiền điện tử khác nhau như Bitcoin, Ethereum, và các token khác.</li>
                <li>Ví điện tử thông thường: Thường chỉ hỗ trợ các loại tiền tệ pháp định như VND, USD, EUR.</li>
              </ul>
            </li>
            <li>
              <strong>Tiện lợi và ứng dụng:</strong>
              <ul>
                <li>Ví Blockchain: Chủ yếu được sử dụng cho các giao dịch tiền điện tử, đầu tư và lưu trữ tài sản kỹ thuật số.</li>
                <li>Ví điện tử thông thường: Thường được sử dụng cho các giao dịch hàng ngày như thanh toán mua sắm, chuyển tiền giữa các tài khoản ngân hàng, nạp tiền điện thoại, v.v.</li>
              </ul>
            </li>
          </ul>
        </div>

        <div className="faq-section">
          <h2 id="s3">Các từ ghi nhớ, khóa công khai và khóa riêng của ví blockchain là gì?<FaLink title="Sao chép liên kết này" onClick={() => handleCopy('s3')} /></h2>
          <p>Public key và Private key hay khóa công khai và khóa cá nhân là một phần không thể thiếu của Bitcoin và các loại tiền điện tử khác. Hai loại key này phép bạn gửi và nhận tiền điện tử mà không yêu cầu bên thứ ba xác minh các giao dịch.</p>
          <p>Các khóa này để gửi tiền điện tử cho bất kỳ ai, ở bất kỳ đâu, bất kỳ lúc nào. Public key và Private key kết hợp với nhau như một cặp khóa. Bạn có thể chia sẻ public key của mình để nhận giao dịch. Nhưng Private key của bạn phải được giữ bí mật. Bất kỳ ai đó có quyền truy cập vào các khóa cá nhân của bạn. Đồng nghĩa họ cũng sẽ có quyền truy cập vào tài sản điện tử của bạn ở trong đó.</p>
          <ul>
            <li>
              <strong>Private key là gì?</strong>
              <p>Private key cung cấp cho bạn khả năng chứng minh quyền sở hữu hoặc sử dụng, chi tiêu số tiền liên quan đến địa chỉ công khai của bạn. Hãy bảo mật private key bằng cách tốt nhất có thể. Private key có thể có những dạng như sau:</p>
              <ul>
                <li>Mã nhị phân dài 256 ký tự.</li>
                <li>Mã thập lục phân 64 chữ số.</li>
                <li>Mã QR.</li>
                <li>Cụm từ dễ nhớ.</li>
              </ul>
            </li>
            <li>
              <strong>Public key là gì?</strong>
              <p>Public key cho phép bạn nhận các giao dịch tiền điện tử. Đó là một mã mật mã được ghép nối với một private key . Bất kỳ ai cũng có thể gửi các giao dịch tới public key. Nhưng bạn cần private key để “mở khóa” chúng và chứng minh rằng bạn là chủ sở hữu của tiền điện tử nhận được trong giao dịch. Public key có thể nhận giao dịch thường là một địa chỉ ví. Đây chỉ đơn giản là một dạng rút gọn của public key của bạn.</p>
            </li>
          </ul>
        </div>

        <div className="faq-section">
          <h2 id="s4">Làm thế nào để có được một ví blockchain?<FaLink title="Sao chép liên kết này" onClick={() => handleCopy('s4')} /></h2>
          <p>Khi đăng nhập vào Yvote tại trang web <a href={`${config.api.siteURL}/myWeb3Info`} target="_blank">{`${config.api.siteURL}/myWeb3Info`}</a>, một ví đã được tạo sẵn cho bạn và bạn có thể sử dụng nó ngay lập tức.</p>
        </div>

        <div className="faq-section">
          <h2 id="s5">Tiền kỹ thuật số là gì?<FaLink title="Sao chép liên kết này" onClick={() => handleCopy('s5')} /></h2>
          <p>Tiền kỹ thuật số, hay còn gọi là tiền điện tử, là một dạng tiền tệ được số hóa và hoạt động trên các nền tảng kỹ thuật số. Tiền kỹ thuật số không có hình thức vật lý như tiền giấy hoặc tiền xu, mà tồn tại dưới dạng các mã số và có thể được sử dụng để thực hiện các giao dịch trực tuyến. Một số loại tiền kỹ thuật số phổ biến hiện nay bao gồm Bitcoin, Ethereum và Litecoin. Các giao dịch bằng tiền kỹ thuật số thường được thực hiện qua các công nghệ blockchain, đảm bảo tính minh bạch và an toàn.</p>
        </div>

        <div className="faq-section">
          <h2 id="s6">NFT là gì? Quà tặng kỹ thuật số cũng là một NFT phải không?<FaLink title="Sao chép liên kết này" onClick={() => handleCopy('s6')} /></h2>
          <p>NFT (Non-Fungible Token) là một loại tài sản kỹ thuật số đại diện cho quyền sở hữu đối với một đối tượng duy nhất, không thể thay thế hoặc hoán đổi cho bất kỳ thứ gì khác. NFT được lưu trữ trên blockchain, giúp đảm bảo tính minh bạch và xác thực quyền sở hữu. Các NFT thường được sử dụng trong các lĩnh vực như nghệ thuật số, sưu tầm, trò chơi điện tử, và nhiều lĩnh vực khác.</p>
          <p>Quà tặng kĩ thuật số Anh Trai cũng là một NFT vì nó là một đối tượng kỹ thuật số duy nhất, và quyền sở hữu của nó có thể được xác minh và chuyển nhượng thông qua blockchain.</p>
        </div>

        <div className="faq-section">
          <h2 id="s7">NFT có thể được giao dịch không?<FaLink title="Sao chép liên kết này" onClick={() => handleCopy('s7')} /></h2>
          <p>Có, NFT có thể được giao dịch. Khi một NFT được tạo ra, nó có thể được mua, bán, hoặc trao đổi trên các nền tảng và chợ giao dịch NFT, như OpenSea, Rarible, và Foundation. Quyền sở hữu của NFT được ghi lại trên blockchain, giúp xác thực và theo dõi các giao dịch. Điều này cho phép người dùng giao dịch NFT một cách an toàn và minh bạch.</p>
        </div>

        <div className="faq-section">
          <h2 id="s8">Điều gì ảnh hưởng đến giá của NFT?<FaLink title="Sao chép liên kết này" onClick={() => handleCopy('s8')} /></h2>
          <p>Giá của NFT có thể bị ảnh hưởng bởi nhiều yếu tố, bao gồm:</p>
          <ul>
            <li><strong>Sự Hiếm Có:</strong> NFT càng hiếm, giá trị của nó thường càng cao. NFT độc quyền hoặc có số lượng hạn chế thường có giá cao hơn.</li>
            <li><strong>Nổi Tiếng và Danh Tiếng:</strong> NFT của các nghệ sĩ, nhà sáng tạo, hoặc người nổi tiếng có thể có giá cao hơn nhờ danh tiếng và ảnh hưởng của họ.</li>
            <li><strong>Chất Lượng Nội Dung:</strong> Chất lượng và độ sáng tạo của nội dung NFT cũng ảnh hưởng đến giá trị của nó.</li>
            <li><strong>Tính Thẩm Mỹ và Độc Đáo:</strong> NFT có thiết kế và phong cách độc đáo có thể thu hút sự chú ý và tăng giá trị.</li>
            <li><strong>Tính Đầu Tư và Giá Trị Văn Hóa:</strong> Một số NFT có thể trở thành tài sản đầu tư có giá trị hoặc mang ý nghĩa văn hóa đặc biệt.</li>
          </ul>
          <p>Một số ví dụ về NFT giá cao của người nổi tiếng bao gồm:</p>
          <ul>
            <li><strong>"Everydays: The First 5000 Days" của Beeple:</strong> Được bán với giá 69 triệu USD tại Christie’s, đây là một trong những NFT đắt giá nhất từng được bán.</li>
            <li><strong>"CryptoPunk #7804":</strong> Một trong các NFT thuộc bộ sưu tập CryptoPunks, đã được bán với giá 7.6 triệu USD.</li>
            <li><strong>"Crossroads" của Beeple:</strong> Một tác phẩm nghệ thuật NFT khác của Beeple đã bán với giá khoảng 6.6 triệu USD.</li>
          </ul>
        </div>

        <h1>2.Câu hỏi thường gặp của Vượt Ngàn Chông Gai</h1>

        <div className="faq-section">
          <h2 id="s9">Các gói bình chọn 1PRODUCTION đang cung cấp gồm những loại nào?<FaLink title="Sao chép liên kết này" onClick={() => handleCopy('s9')} /></h2>
          <p>Các gói được phân biệt theo cấp độ và độ hiếm với các cấp độ từ 1 đến 5 (Bronze, Sliver, Gold, Platinum, Diamond).</p>
          <p>Mỗi cấp độ bao gồm 2 thuộc tính: Thông thường và Lửa, trong đó thuộc tính Lửa là hiếm, có giá trị cao và đặc biệt thuộc vật phẩm sưu tầm hiếm.</p>
        </div>
        <div className="faq-section">
          <h2 id="s10">Thông tin chi tiết của từng gói?<FaLink title="Sao chép liên kết này" onClick={() => handleCopy('s10')} /></h2>
          <ul>
            <li>Bronze: 1 quà tặng số cấp 1 là vật phẩm ngẫu nhiên của một Anh Trai, 4 phiếu bầu và tối đa 99 phiếu bầu hằng tuần.</li>
            <li>Sliver: 1 quà tặng số cấp 2 là vật phẩm ngẫu nhiên của một Anh Trai, 20 phiếu bầu và tối đa 99 phiếu bầu hằng tuần.</li>
            <li>Gold: 1 quà tặng số cấp 3 là vật phẩm ngẫu nhiên của một Anh Trai, 80 phiếu bầu và tối đa 199 phiếu bầu hằng tuần.</li>
            <li>Platinum: 1 quà tặng số cấp 4 là vật phẩm ngẫu nhiên của một Anh Trai, 120 phiếu bầu và tối đa 399 phiếu bầu hằng tuần.</li>
            <li>Diamond: 1 quà tặng số cấp 5 là vật phẩm ngẫu nhiên của một Anh Trai, 360 phiếu bầu và tối đa 999 phiếu bầu hằng tuần.</li>
          </ul>
          <p>Lưu ý: Các gói quy đổi/cải tiến không bao gồm phiếu bầu.</p>
        </div>
        <div className="faq-section">
          <h2 id="s11">Làm thế nào để thêm các phiếu bầu vào tài khoản của tôi?<FaLink title="Sao chép liên kết này" onClick={() => handleCopy('s11')} /></h2>
          <p>Sau khi thanh toán thành công, hệ thống sẽ tự động thêm số phiếu bình chọn vào tài khoản của bạn. Ngay sau đó có thể bình chọn ngay Anh Trai bạn yêu thích nhất.</p>
        </div>
        <div className="faq-section">
          <h2 id="s12">Làm thế nào để nâng cấp lượt bình chọn hằng tuần?<FaLink title="Sao chép liên kết này" onClick={() => handleCopy('s12')} /></h2>
          <ul>
            <li>Nếu tài khoản của bạn đang ở cấp độ 2 (Sliver) và nâng cấp lên cấp 3 (Gold), tài khoản của bạn sẽ được tăng giới hạn số lượt bình chọn lên đến 199 lượt/tuần.</li>
            <li>Nếu mua thêm cấp 3 (Gold) vẫn sẽ không tăng số lượng giới hạn bình chọn mỗi tuần. Thay vào đó hãy nâng cấp lên gói 4 (Platinum) và gói 5 (Diamond).</li>
          </ul>
        </div>
        <div className="faq-section">
          <h2 id="s13">Quà tặng số là gì? Tại sao phải mua quà tặng số khi bình chọn?<FaLink title="Sao chép liên kết này" onClick={() => handleCopy('s13')} /></h2>
          <ul>
            <li>Quà tặng số là một loại tài sản kĩ thuật số độc nhất, được sử dụng để bình chọn trên nền tảng YVote.</li>
            <li>Mỗi quà tặng số đại diện cho một nhân vật trong chương trình, nếu sở hữu chúng sẽ giúp bạn thể hiện sự ủng hộ của mình một cách đặc biệt đến nhân vật được đề cử.</li>
          </ul>
        </div>
        <div className="faq-section">
          <h2 id="s14">Tại sao quà tặng số có giá trị?<FaLink title="Sao chép liên kết này" onClick={() => handleCopy('s14')} /></h2>
          <ul>
            <li>Tính độc đáo và khan hiếm: Mỗi quà tặng số là duy nhất. Tính chất giới hạn làm cho chúng khan hiếm, do đó có giá trị cao.</li>
            <li>Quyền sở hữu: Khi bạn mua một quà tặng số, bạn có quyền sở hữu mã thông báo duy nhất. Mặc dù tài sản số có thể được sao chép, nhưng quyền sở hữu được ghi lại trên blockchain không thể sao chép được.</li>
            <li>Khả năng sưu tầm và giao dịch: Giống như sưu tầm các vật phẩm hiếm hoặc nghệ thuật, quà tặng số có thể được sưu tầm, trưng bày hoặc thậm chí bán. Giá trị của chúng có thể tăng theo thời gian, đặc biệt nếu chúng có nhu cầu cao hoặc trở nên có ý nghĩa văn hoá.</li>
            <li>Kết nối với ngôi sao: Sở hữu một quà tặng số từ chương trình của chúng tôi cho phép bạn có một món kỷ niệm số trực tiếp liên kết với ngôi sao yêu thích của bạn, tăng giá trị tình cảm và tiềm năng giá trị trong tương lai của nó.</li>
          </ul>
        </div>
        <div className="faq-section">
          <h2 id="s15">Cách thức nhận Quà tặng số<FaLink title="Sao chép liên kết này" onClick={() => handleCopy('s15')} /></h2>
          <ul>
            <li>Nhận được thông qua mua Gói: Mỗi Gói sẽ ngẫu nhiên mở ra một Quà tặng số trong thời gian thường ngày, vào ngày sinh nhật của anh trai sẽ có xác suất được tặng kèm một Quà tặng số kỷ niệm sinh nhật của anh trai này.</li>
          </ul>
        </div>
        <div className="faq-section">
          <h2 id="s16">Làm thế nào để mua gói bình chọn?<FaLink title="Sao chép liên kết này" onClick={() => handleCopy('s16')} /></h2>
          <p>Bạn có thể nhấp vào gói mua mong muốn từ cổng mua sự kiện hoặc truy cập trực tiếp vào trang mua gói tại: <a href={`${config.api.siteURL}/buyNft`} target="_blank">{`${config.api.siteURL}/buyNft`}</a></p>
        </div>
        <div className="faq-section">
          <h2 id="s17">Gói Lửa có gì đặc biệt so với các gói còn lại?<FaLink title="Sao chép liên kết này" onClick={() => handleCopy('s17')} /></h2>
          <p>Gói Lửa là gói phiên bản giới hạn, chứa một quà tặng số hiếm, giá trị cao và có thể mang lại lợi ích khi giao dịch trên thị trường. Giá trị hơn nữa là gói Lửa có thể mang theo lại quyền lợi đặc biệt, cũng như số lượt bình chọn tối đa trong tuần.</p>
        </div>
        <div className="faq-section">
          <h2 id="s18">Làm thế nào để sưu tầm gói Lửa?<FaLink title="Sao chép liên kết này" onClick={() => handleCopy('s18')} /></h2>
          <ul>
            <li>Gói Lửa không thể mua trực tiếp, có thể thông qua tổng hợp. Bạn cần thu thập 5 quà tặng số cấp thấp hơn và sau đó tổng hợp chúng thành một gói cao cấp hơn (ví dụ: 5 quà tặng số Bronze thành một gói Sliver).</li>
            <li>Nếu bạn có nhiều quà tặng số cấp thấp, hãy can đảm thử tổng hợp và chúc bạn may mắn!</li>
            <li>Lưu ý: Quà tặng số được sử dụng cho tổng hợp sẽ bị hủy khi tổng hợp thành công.</li>
          </ul>
          <p>Trang tổng hợp: <a href={`${config.api.siteURL}/synthesizeNft`} target="_blank">{`${config.api.siteURL}/synthesizeNft`}</a></p>
        </div>
        <div className="faq-section">
          <h2 id="s21">Có thể rút quà tặng số về ví của tôi không?<FaLink title="Sao chép liên kết này" onClick={() => handleCopy('s21')} /></h2>
          <ul>
            <li>Có, bạn có thể gửi quà tặng số đến ví của bạn.</li>
            <li>Bước 1: Trên trang <a href={`${config.api.siteURL}/myNftList`}>QUÀ TẶNG SỐ CỦA TÔI</a>, tìm quà tặng số bạn muốn gửi về ví, mở chi tiết và nhấp gửi. Trong giao diện xuất hiện, nhập FunID của bạn.</li>
            <li>Bước 2: Nhấp Gửi hệ thống sẽ gửi quà tặng số đến địa chỉ ví của bạn. Lưu ý bạn sẽ chịu phí giao dịch khi rút về ví của bạn.</li>
          </ul>
        </div>
        <div className="faq-section">
          <h2 id="s22">Làm thế nào để xem lịch sử giao dịch quà tặng số của tôi?<FaLink title="Sao chép liên kết này" onClick={() => handleCopy('s22')} /></h2>
          <p>
            Bạn có thể xem lịch sử rút tiền, gửi và nhận quà tặng số thông qua trang <a href={`${config.api.siteURL}/history`} target="_blank">Lịch sử Chuyển nhượng</a>(<a href={`${config.api.siteURL}/history`} target="_blank">{`${config.api.siteURL}/history`}</a>).
          </p>
        </div>
        <div className="faq-section">
          <h2 id="s23">Quà tặng số có giống như thẻ vật lý truyền thống không, và nó được dùng để làm gì?<FaLink title="Sao chép liên kết này" onClick={() => handleCopy('s23')} /></h2>
          <p>Định nghĩa chính thức quốc tế của quà tặng số là NFT (Non-Fungible Token). Đây là một loại tài sản kỹ thuật số dựa trên công nghệ blockchain. Nó đảm bảo rằng thẻ kỹ thuật số bạn nhận được là duy nhất, không thể sao chép, không thể thay đổi và ghi nhận giao dịch có thể được theo dõi. So với thẻ vật lý truyền thống, trước tiên, nó sẽ không bị hỏng về mặt vật lý. Miễn là Internet vẫn hoạt động, nó có thể luôn tồn tại. Ngoài ra, nó có thể dễ dàng được đẩy lên một mức giá rất cao. Bởi vì một khi sự khan hiếm của một NFT nhất định được đồng ý trên Internet, trong tất cả các thị trường giao dịch trực tuyến được hỗ trợ, sẽ có người muốn mua nó. Nhưng giao dịch thẻ vật lý chỉ có thể được thực hiện ngoại tuyến. Hiệu quả rất thấp, và dễ bị mất, hỏng hoặc thậm chí bị làm giả.</p>
        </div>
        <h2>3.Hướng dẫn vận hành</h2>
        <div className="faq-section">
          <h2 id="s25">Làm cách nào để xem quà tặng số được trích xuất trong OKX?<FaLink title="Sao chép liên kết này" onClick={() => handleCopy('s25')} /></h2>
          <ul>
            <li>Đầu tiên,truy cập <a href="https://www.okx.com/vi/download" target="_blank">https://www.okx.com/vi/download</a> để tải xuống Ứng dụng OKX.</li>
            <li>Sau khi cài đặt Ứng dụng, đăng ký hoặc đăng nhập vào tài khoản OKX theo hướng dẫn.</li>
            <li>Trên trang chủ, nhấp vào "Ví" ở phía trên và sau đó nhấp vào "Tôi đã có" ở phía dưới.</li>
            <li>
              <ImageLoader css={"pic"} src={`https://storage.googleapis.com/open-res/faq/faq-okx-1.jpeg`} alt={``} />
            </li>
            <li>Khi nút bật lên, chọn "nhập cụm từ khôi phục hoặc khóa riêng".</li>
            <li>
              <ImageLoader css={"pic"} src={`https://storage.googleapis.com/open-res/faq/faq-okx-2.jpeg`} alt={``} />
            </li>
            <li>Sau đó sao chép khóa riêng của ví Yvote và điền vào (Khóa riêng có thể được lấy từ trang này:<a href={`${config.api.siteURL}/myWeb3Info`} target="_blank">{`${config.api.siteURL}/myWeb3Info`}</a>. Nhấp vào nút sao chép và sau đó dán trực tiếp vào ô nhập liệu của ứng dụng OKX.) Sau khi điền xong, nhấp vào "xác nhận".</li>
            <li>
              <ImageLoader css={"pic"} src={`https://storage.googleapis.com/open-res/faq/faq-okx-3.jpeg`} alt={``} />
            </li>
            <li>Sau đó, ứng dụng sẽ yêu cầu bạn tạo mật khẩu cho ví này (Mật khẩu này sẽ được sử dụng cho các giao dịch với ví này), sau đó nhấp vào xác nhận.</li>
            <li>
              <ImageLoader css={"pic"} src={`https://storage.googleapis.com/open-res/faq/faq-okx-4.jpeg`} alt={``} />
            </li>
            <li>Chọn mạng của ví này: đầu tiên là các mạng EVM, sau đó nhấp vào "xác nhận".</li>
            <li>
              <ImageLoader css={"pic"} src={`https://storage.googleapis.com/open-res/faq/faq-okx-5.jpeg`} alt={``} />
            </li>
            <li>Điều này sẽ tải ví của bạn, sau đó bạn có thể nhấp vào "NFTs" trong giao diện ví sau khi quá trình tải hoàn tất để xem quà tặng số của bạn.</li>
            <li>
              <ImageLoader css={"pic"} src={`https://storage.googleapis.com/open-res/faq/faq-okx-6.jpeg`} alt={``} />
            </li>
          </ul>
        </div>
        <div className="faq-section">
          <h2 id="s26">Hướng dẫn Vận hành để Bán NFT (quà tặng số) trên OKX<FaLink title="Sao chép liên kết này" onClick={() => handleCopy('s26')} /></h2>
          <ul>
            <li>Trong giao diện ví của bạn, nhấp vào "NFTs". Chọn quà tặng số mà bạn muốn bán và nhấp vào "Bán" ở phía dưới.</li>
            <li>
              <ImageLoader css={"pic"} src={`https://storage.googleapis.com/open-res/faq/faq-okx-7.jpeg`} alt={``} />
            </li>
            <li>Trong giao diện tiếp theo, đặt giá bán mong muốn cho NFT này và loại tiền điện tử được sử dụng cho giao dịch (vì nó nằm trên chuỗi, nên khuyến nghị sử dụng USDT cho giao dịch), và nhấp vào "Niêm Yết" sau khi hoàn tất cài đặt.</li>
            <li>
              <ImageLoader css={"pic"} src={`https://storage.googleapis.com/open-res/faq/faq-okx-8.jpeg`} alt={``} />
            </li>
            <li>Sau khi xác minh mật khẩu, bạn đã thành công niêm yết NFT này trên thị trường.</li>
            <li>
              <ImageLoader css={"pic"} src={`https://storage.googleapis.com/open-res/faq/faq-okx-9.jpeg`} alt={``} />
            </li>
          </ul>
        </div>
        <div className="faq-section">
          <h2 id="s27">Hướng dẫn Mua quà tặng số “Call me by fire” trên OKX<FaLink title="Sao chép liên kết này" onClick={() => handleCopy('s27')} /></h2>
          <ul>
            <li>Mở ứng dụng OKX, nhấp vào "Marketplace" trong thanh điều hướng dưới cùng để mở giao diện thị trường, sau đó nhấp vào hộp tìm kiếm phía trên.</li>
            <li>
              <ImageLoader css={"pic"} src={`https://storage.googleapis.com/open-res/faq/faq-okx-10.jpeg`} alt={``} />
            </li>
            <li>Nhập "call me by fire" vào hộp tìm kiếm. Chọn những mục bạn quan tâm để mở chi tiết, và sau đó bạn có thể đưa ra đề nghị hoặc mua trực tiếp.</li>
            <li>
              <ImageLoader css={"pic"} src={`https://storage.googleapis.com/open-res/faq/faq-okx-11.jpeg`} alt={``} />
            </li>
          </ul>
        </div>
      </div >
      <Footer />
    </div >
  );
}

export default Faq;