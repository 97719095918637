import React from "react";
import "./index.css";

const Button = ({ size, css, onClick, children }) => {
  let buttonClass = "";

  if (css) {
    buttonClass = css;
  } else {
    buttonClass = `button ${size}-button`;
  }

  return (
    <button className={buttonClass} onClick={onClick}>
      {children}
    </button>
  );
};

export default Button;
