import React from 'react';

import TopNavigationBar from "../../components/TopNavigationBar";
import Seo from "../../components/Seo";
import Footer from "../../components/Footer";

import "./index.css";

const MaintenancePage = () => {
    return (
        <div className="app">
            <Seo
                title={`Yvote - Đang bảo trì nâng cấp`}
                description={`Yvote - Funfusion Đang bảo trì nâng cấp`}
                keywords={`Yvote,Funfusion,NFT,Web3,Đang bảo trì nâng cấp`}
                image={`${window.location.host}/${process.env.PUBLIC_URL}/images/funfusion.png`}
                url={window.location.href}
            />
            <TopNavigationBar check={false} />
            <div className="maintenance">
                <h1>Đang bảo trì nâng cấp...</h1>
                <p>Chúng tôi đang tiến hành một số công việc bảo trì. Vui lòng quay lại sau.</p>
            </div >
            <Footer />
        </div >
    );
};

export default MaintenancePage;
