import React, { useEffect } from 'react';

import Button from "../../components/Button";

const initializeFacebookSDK = () => {
    window.fbAsyncInit = function () {
        window.FB.init({
            appId: '1231966414908362',
            cookie: true,
            xfbml: true,
            version: 'v13.0'
        });
        window.FB.AppEvents.logPageView();
    };

    (function (d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) { return; }
        js = d.createElement(s); js.id = id;
        js.src = "https://connect.facebook.net/en_US/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
};

const FacebookShareButton = ({ quote, imageUrl }) => {
    console.log('quote=>', quote, 'imageUrl=>', imageUrl);

    useEffect(() => {
        initializeFacebookSDK();
    }, []);

    const shareOnFacebook = () => {
        window.FB.ui({
            method: 'share',
            display: 'popup',
            href: imageUrl,
        }, function (response) {
            // console.log('window.FB.ui=>response=>', response);
            if (response && !response.error_message) {
                // console.log('Sharing success!');
            } else {
                // console.log('Error while sharing.');
            }
        });
    };

    return (
        <Button size={"small-default"} onClick={shareOnFacebook}>
            Chia sẻ ngay
        </Button>
    );
};

export default FacebookShareButton;